import './App.css';
import {NavBar} from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Slider} from "./components/Slider"
import Images from "./images"
import pageTop from './assets/page-top.png';
import pageBottom from './assets/page-bottom.png';


function App() {
  return (
    <>
    <NavBar/>
    <Slider images={Images} />
    <div className="text-center" id="nostrastoria">
      <div className="container section">
        <img src={pageTop} alt=""/>
        <div className="separator">
        <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"></span></span>
          <h4>Le Campagnole Wine Bar</h4>
          <span className="vc_sep_holder vc_sep_holder_l"><span className="vc_sep_line"></span></span>
        </div>
        
        <h1 className="section-title">LA NOSTRA STORIA</h1>
        <p className="caption">
        Le Campagnole Wine Bar nasce dall’idea di dare un servizio mirato alla clientela. 
        Personale qualificato a servire taglieri di salumi e insaccati ricercati di cui la mortadella! 
        Tagliata a coltello che abbinata alla selezione di vini italiani e francesi è un’abbinamento ideale per deliziare i palati piú raffinati.
        </p>
        <img src={pageBottom} alt=""/>
      </div>
      
    </div>
    </>
  );
}

export default App;
