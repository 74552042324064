import {useState, useEffect} from "react";
import {Navbar,Container,Nav} from "react-bootstrap";
import logo from "../assets/le-campagnole.png";


export const NavBar = () => {
    const [activeLink,setActiveLink] = useState("home");
    const [scrolled,setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if(window.scrollY > 50){
                setScrolled(true);
            }else{
                setScrolled(false);
            }
        }

        window.addEventListener("scroll",onScroll);

        return() => window.removeEventListener("scroll",onScroll);
    }, []);

    const onUpdateActiveLink = (link) => {
        setActiveLink(link);
    }
return(
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
            <Navbar.Brand href="#home">
            <img src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" > 
            <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" className="links">
            <Nav className="me-auto">
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#nostrastoria" className={activeLink === 'nostrastoria' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('nostrastoria')}>La nostra storia</Nav.Link>
                <Nav.Link href="#galleria" className={activeLink === 'galleria' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('galleria')}>Galleria</Nav.Link>
                <Nav.Link href="#contatti" className={activeLink === 'contatti' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('contatti')}>Info e Contatti</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
  </Navbar>
);
}