import React, { useRef,useEffect } from 'react';
import useSlider from "../hooks/useSlider"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/le-campagnole-logo.png';
const delay = 10000;

export const Slider = ({images}) => {
    const slideImage = useRef(null)
    const slideText = useRef(null)
    const { goToPreviousSlide, goToNextSlide } = useSlider(
        slideImage,
        slideText,
        images
    )

    useEffect(() => {
      setTimeout(
        () =>
        goToNextSlide()
        ,
        delay
      );
  
        return () => {};
      }, []);

    return(
        <div className="slider" ref={slideImage}>
        <div className="slider--content">
          <button onClick={goToPreviousSlide} className="slider__btn-left">
          <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div className="slider--feature">
          <h1 className="feature--title">Dreaming</h1>
            <p ref={slideText} className="feature--text"></p>
            <button className="feature__btn">Get started</button>
          </div>
          <button onClick={goToNextSlide} className="slider__btn-right">
          <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    );
}