export default [
    {
      src: "https://www.lecampagnolewine.it/wp-content/uploads/2019/10/le-campagnole-bg.jpg",
      text: "Duis aute irure dolor in reprehenderit in voluptate velit esse",
    },
    {
      src: "https://drive.google.com/uc?id=1rJFs-8So16UCiDag__hG4yyf_RnC08Fa",
      text: "Consectetur adipisicing elit cillum dolore eu fugiat nulla",
    },
    {
      src: "https://drive.google.com/uc?id=1HO2AGjd_1yyYI4pYTTBmGXBaWHoGSqCl",
      text: "Asperiores ex animi explicabo cillum dolore eu fugiat nulla",
    },
  ]